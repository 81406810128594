import React, { useRef, useState, useEffect } from 'react';
import parse from 'html-react-parser';

function App() {
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const defaultImage = '/test.png'; // Replace with your default PNG path
  const [url, setUrl] = useState(''); // State to store the entered URL
  const [htmlContent, setHtmlContent] = useState('');

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [defaultImg, setDefaultImg] = useState(new Image());
  const [startDragPosition, setStartDragPosition] = useState(null);
  useEffect(() => {
    // Preload the default image on component mount
    const img = new Image();
    img.onload = () => {
      setDefaultImg(img);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 600, 600);
    };
    img.src = defaultImage;
  }, []);
  useEffect(() => {
    if(htmlContent !== ''){
      extractDataFromHtml()
    }
  }, [htmlContent]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
};

const fetchHtmlContent = async () => {
    try {
        const response = await fetch(url);
        const html = await response.text();
        setHtmlContent(html);
    } catch (error) {
        console.error("Error fetching the URL:", error);
    }
};

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setUploadedImage(img);
          renderCanvas(img);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const downloadLink = document.createElement('a');
    downloadLink.href = canvas.toDataURL('image/png');
    downloadLink.download = 'combined-image.png';
    downloadLink.click();
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const boundingRect = canvasRef.current.getBoundingClientRect();
      const x = e.clientX - boundingRect.left;
      const y = e.clientY - boundingRect.top;
      setPosition({ x: x - 150, y: y - 150 });
      renderCanvas();
    }
  };

  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
    renderCanvas();
  };
  const renderCanvas = (uploadedImg = uploadedImage) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // If uploaded image is available
    if (uploadedImg) {
        const scaledWidth = uploadedImg.width * scale * zoom;
        const scaledHeight = uploadedImg.height * scale * zoom;

        // Calculate position to center the uploaded image, then add the user's drag/move adjustments
        const centerX = ((canvas.width - scaledWidth) / 2) + position.x;
        const centerY = ((canvas.height - scaledHeight) / 2) + position.y;

        // Set a circular clipping region
        ctx.save();
        ctx.beginPath();
        ctx.arc(canvas.width / 2, canvas.height / 2, Math.min(canvas.width, canvas.height) / 2, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.clip();

        // Draw the uploaded image centered and inside the clipping region
        ctx.drawImage(
            uploadedImg,
            centerX,
            centerY,
            scaledWidth,
            scaledHeight
        );

        ctx.restore();
    }

    // Draw the default image over the uploaded image
    ctx.drawImage(defaultImg, 0, 0, 600, 600);
};

const handleTouchStart = (e) => {
  setIsDragging(true);
  setStartDragPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
};

const handleTouchEnd = () => {
  setIsDragging(false);
  setStartDragPosition(null);
};

const handleTouchMove = (e) => {
  e.preventDefault(); // Prevent scrolling while moving the image
  if (isDragging && startDragPosition) {
    const dx = e.touches[0].clientX - startDragPosition.x;

    setPosition((prevPosition) => ({
        x: prevPosition.x + dx,
        y: prevPosition.y // Keep the y-coordinate constant
    }));

    setStartDragPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    renderCanvas();
}
};

const extractDataFromHtml = () => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  // For this example, let's say we want to extract the content of the first <h1> tag
  const h1Element = doc.querySelector('h1');
  if (h1Element) {
      console.log(h1Element.textContent);
  } else {
    console.log('No <h1> tag found');
  }
};
  return (
    <div className="App">
      
        <span className="title">
          HELP FREE THE HOSTAGES NOW
        </span>      
        <div className='clock-con'>
        <div className='clock'>
      <div id="bthn" lang="en"></div>
      </div>
        </div>
        
      <span className='sub-title' >
      International pressure and public awareness is critical!
      </span>
      <ol className='ol'>
        <li>Select a picture for your profile on social media</li>
        <li>Adjust and download. Your new picture will be saved in your Files folder.</li>
        <li>Save your picture into the photo album</li>
        <li>Replace your profile picture on Facebook & Instagram with the your new picture.</li>
        <li>Don’t forget to tag your post with #FreeTheHostagesNow and share this website: www.freethehostagesnow.com</li>
      </ol>
      <div className="upload">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageUpload}
        accept="image/*"
      />
      
      <label>
        Zoom:
        <input
          type="range"
          min="0.1" // Adjusted minimum value
          max="3"
          step="0.1"
          value={zoom}
          onChange={handleZoomChange}
        />
      </label>
      <canvas
        ref={canvasRef}
        width="600" 
        height="600" 
        style={{ width: '300px', height: '300px' }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
      ></canvas>
      <button className='download' onClick={handleDownload}>Download</button>
      </div>
      
    </div>

  );
}

export default App;
